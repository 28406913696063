const scrollbarWidth = 6
const scrollbarBorderRadius = 12
const scrollbarBackgroundColor = "#dedede"
const thumbBorderRadius = 12
const thumbColor = "#8a8a8a"
const hoverColor = "#4d4d4d"
const focusColor = "#000000"

import type { ComponentType } from "react"
import { addPropertyControls, ControlType } from "framer"
import React, { useEffect, useState } from "react"

export function customScrollbar(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            const styleTag = document.createElement("style")
            styleTag.innerHTML = `
                
                ::-webkit-scrollbar {
                    width: ${scrollbarWidth}px;
                }
                ::-webkit-scrollbar-track {
                    background: ${scrollbarBackgroundColor};
                    padding: 4px;
                    border-radius: ${scrollbarBorderRadius}px;,
                }
                ::-webkit-scrollbar-thumb {
                    background-color: ${thumbColor};
                    border: transparent;
                    border-radius: ${thumbBorderRadius}px;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background-color: ${hoverColor};
                }
                ::-webkit-scrollbar-thumb:active {
                    background-color: ${focusColor};
                }
            `
            document.head.appendChild(styleTag)

            return () => {
                document.head.removeChild(styleTag)
            }
        }, [
            scrollbarWidth,
            thumbColor,
            scrollbarBackgroundColor,
            scrollbarBorderRadius,
            thumbBorderRadius,
            hoverColor,
            focusColor,
        ])

        return <Component {...props} />
    }
}
